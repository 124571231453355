define("ember-simple-auth/configuration", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULTS = {
    rootURL: ''
  };

  /**
    Ember Simple Auth's configuration object.
  
    @class Configuration
    @extends Object
    @module ember-simple-auth/configuration
    @public
  */
  var _default = _exports.default = {
    /**
      The root URL of the application as configured in `config/environment.js`.
       @property rootURL
      @readOnly
      @static
      @type String
      @default ''
      @public
    */
    rootURL: DEFAULTS.rootURL,
    load: function load(config) {
      this.rootURL = (0, _object.getWithDefault)(config, 'rootURL', DEFAULTS.rootURL);
    }
  };
});