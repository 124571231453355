define("ember-simple-auth/mixins/unauthenticated-route-mixin", ["exports", "@ember/service", "@ember/object/mixin", "@ember/debug", "@ember/application"], function (_exports, _service, _mixin, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */

  /**
   *
   * @param {ApplicationInstance} owner The ApplicationInstance that owns the session service
   * @param {(...args: [any]) => any} callback Callback that will be invoked if the user is authenticated
   */
  function runIfAuthenticated(owner, callback) {
    var sessionSvc = owner.lookup('service:session');
    if (sessionSvc.get('isAuthenticated')) {
      callback();
      return true;
    }
  }

  /**
    __This mixin is used to make routes accessible only if the session is
    not authenticated__ (e.g., login and registration routes). It defines a
    `beforeModel` method that aborts the current transition and instead
    transitions to the
    {{#crossLink "UnauthenticatedRouteMixin/routeIfAlreadyAuthenticated:property"}}{{/crossLink}}
    if the session is authenticated.
  
    ```js
    // app/routes/login.js
    import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
  
    export default Ember.Route.extend(UnauthenticatedRouteMixin);
    ```
  
    @class UnauthenticatedRouteMixin
    @module ember-simple-auth/mixins/unauthenticated-route-mixin
    @extends Ember.Mixin
    @public
  */
  var _default = _exports.default = _mixin.default.create({
    /**
      The session service.
       @property session
      @readOnly
      @type SessionService
      @public
    */
    session: (0, _service.inject)('session'),
    /**
      The route to transition to if a route that implements the
      {{#crossLink "UnauthenticatedRouteMixin"}}{{/crossLink}} is accessed when
      the session is authenticated.
       @property routeIfAlreadyAuthenticated
      @type String
      @default 'index'
      @public
    */
    routeIfAlreadyAuthenticated: 'index',
    /**
      Checks whether the session is authenticated and if it is aborts the current
      transition and instead transitions to the
      {{#crossLink "UnauthenticatedRouteMixin/routeIfAlreadyAuthenticated:property"}}{{/crossLink}}.
       __If `beforeModel` is overridden in a route that uses this mixin, the route's
     implementation must call `this._super(...arguments)`__ so that the mixin's
     `beforeModel` method is actually executed.
       @method beforeModel
      @public
    */
    beforeModel: function beforeModel() {
      var _this = this;
      var didRedirect = runIfAuthenticated((0, _application.getOwner)(this), function () {
        var routeIfAlreadyAuthenticated = _this.get('routeIfAlreadyAuthenticated');
        (true && !(_this.get('routeName') !== routeIfAlreadyAuthenticated) && (0, _debug.assert)('The route configured as UnauthenticatedRouteMixin.routeIfAlreadyAuthenticated cannot implement the UnauthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', _this.get('routeName') !== routeIfAlreadyAuthenticated));
        _this.transitionTo(routeIfAlreadyAuthenticated);
      });
      if (!didRedirect) {
        return this._super.apply(this, arguments);
      }
    }
  });
});