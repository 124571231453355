define("ember-colpick/mixins/col-pick", ["exports", "jquery", "@ember/utils", "@ember/object/mixin", "@ember/object", "@ember/runloop"], function (_exports, _jquery, _utils, _mixin, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-jquery */
  /* eslint-disable ember/no-new-mixins */

  function onRenderObserver(/*keys..., fn*/
  ) {
    var args = Array.prototype.slice.call(arguments);
    var fn = args.slice(-1)[0];
    var keys = args.slice(0, -1);
    var observerFunction = function observerFunction() {
      if (this._state !== 'inDOM') {
        // don't schedule unless inDOM
        return;
      }
      (0, _runloop.schedule)('render', this, function () {
        // don't run unless still inDOM
        if (this._state === 'inDOM') {
          fn.call(this);
        }
      });
    };
    return _object.observer.apply(null, keys.concat([observerFunction]));
  }
  var _default = _exports.default = _mixin.default.create({
    colpickLayout: 'hex',
    colorScheme: 'dark',
    classNames: ['ember-col-pick'],
    flat: true,
    // [true/false] render as popup (true) rendering inline (false)
    value: null,
    previewValue: null,
    useHashtag: false,
    appendPickerTo: undefined,
    _colpick: undefined,
    configDidChange: onRenderObserver('colorScheme', 'colpickLayout', 'flat', function () {
      this._tearDownColpick();
      this.rerender();
    }),
    valueDidChange: onRenderObserver('value', function () {
      var value = this.value;
      if (this._colpick && value) {
        this._colpick.colpickSetColor(value);
      }
    }),
    _setupColpick: function _setupColpick() {
      var _this = this;
      var layout = this.colpickLayout;
      var colorScheme = this.colorScheme;
      if (layout && colorScheme) {
        var colpick = this._colpick = (0, _jquery.default)(this.element).colpick({
          layout: layout,
          colorScheme: colorScheme,
          submit: 0,
          appendTo: this.appendPickerTo,
          flat: this.flat,
          onChange: function onChange(hsb, hex) {
            if (_this.useHashtag) {
              hex = '#' + hex;
            }
            _this.set('previewValue', hex);
            if (_this._isValidPreviewValue()) {
              _this.set('value', hex);
            }
          },
          onHide: function onHide() {
            var _this$onHide;
            (_this$onHide = _this.onHide) === null || _this$onHide === void 0 || _this$onHide.call(_this);
          }
        });
        colpick.find('input[type=text]').keyup(function () {
          var hexInputVal = this.value;
          if (hexInputVal.length === 6) {
            colpick.colpickSetColor(hexInputVal);
          }
        });
        var value = this.value;
        if (value) {
          colpick.colpickSetColor(value);
        }
      }
    },
    _isValidPreviewValue: function _isValidPreviewValue() {
      var previewHex = this.previewValue;
      var validityRegex = this.useHashtag ? /^#[a-f0-9]{6}$/i : /^[a-f0-9]{6}$/i;
      return (0, _utils.isPresent)(previewHex.match(validityRegex));
    },
    popup: function popup() {
      if (this._state === 'inDOM') {
        return (0, _jquery.default)('#' + (0, _jquery.default)(this.element).data('colpickId'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();
      this._setupColpick();
    },
    _tearDownColpick: function _tearDownColpick() {
      if (this._colpick) {
        if (!this.isDestroying) {
          this._colpick.colpickDestroy();
        }
        this._colpick = undefined;
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._tearDownColpick();
      this._super();
    }
  });
});